import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import InnerPage from "../components/InnerPage"

function ArchivTemplate({ data, pageContext }) {
    const posts = data.allMarkdownRemark.edges;
    const { currentPage, numPages } = pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
        currentPage - 1 === 1
            ? '/archiv/'
            : '/archiv/seite/' + (currentPage - 1).toString();
    const nextPage = '/archiv/seite/' + (currentPage + 1).toString();
    return (
        <InnerPage
            pageType="blog-page"
            title="Liste aller erschienenen Artikel"
            description="Liste aller erschienenen Artikel"
        >
            <div className="box-layout-child bg-white">
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-lg-10">
                            {posts.map(({ node }, id) => {
                                return (
                                    <div className={node.frontmatter.feature_image ? `blog-box-layout4` : `blog-box-layout5`} key={id}>
                                        {node.frontmatter.feature_image && (
                                            <div className="item-img">
                                                <Link to={`/posts${node.fields.slug}`}><Img fluid={node.frontmatter.feature_image.childImageSharp.fluid} alt="" /></Link>
                                            </div>
                                        )}
                                        
                                        <div className="item-content">
                                            <ul className="entry-meta meta-color-dark">
                                                <li><i className="fas fa-calendar-alt"></i>{node.frontmatter.date}</li>
                                            </ul>
                                            <h3 className="item-title"><Link to={`/posts${node.fields.slug}`}>{node.frontmatter.title}</Link></h3>
                                            <p className="item-description">{node.excerpt}</p>
                                        </div>
                                    </div>
                                );
                            })}
                            <nav className="navigation pagination my-5 d-flex justify-content-end" role="navigation">
                                <div className="nav-links">
                                    {!isFirst && (
                                        <Link
                                            to={prevPage}
                                            className="prev page-numbers"
                                            rel="prev"
                                        >
                                            « Neuere Beiträge
                                        </Link>
                                    )}
                                    {!isLast && (
                                        <Link
                                            to={nextPage}
                                            className="next page-numbers"
                                            rel="next"
                                        >
                                            Ältere Beiträge »
                                        </Link>
                                    )}
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </InnerPage>
    );
};

export default ArchivTemplate;

export const archivQuery = graphql`
    query archivQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        date(formatString: "DD. MMMM YYYY", locale: "de")
                        feature_image {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 80) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                    excerpt(pruneLength: 70, format: PLAIN)
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
